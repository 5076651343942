import React,{ useState } from 'react'
import {Link} from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import Menu from './menu/Menu'
import Toggle from './menu/Toggle'

//import './CSS/workfreepv_header.css'

import logo from './Media/general/LogoHeader.svg'

function WorkFreeHeader(){
    
    const [navToggled, setNavToggled] = useState(false);

    const handleNavToggle = () => {
      setNavToggled(!navToggled);
    }

    const isMobile = useMediaQuery({ query: '(max-width: 900px)' }) //TODO define limit
    const isDesktop = useMediaQuery({ query: '(min-width: 900px)' })

    return(
    <div className="Header">
        <div className="Logo">
            <img src={logo} className='Header_logo' alt="WorkFreePV Logo" width="470" height="54"></img>
        </div>
       
     
       
            <div className="Menu">
                <Link to="/" className="menu_item">Inicio</Link>
                <Link to="/Who" className="menu_item">Quiénes Somos</Link>       
                <Link to="/Contact" className="menu_item">Contáctanos</Link>
            </div>
        
       
    </div>
    )
}

export default WorkFreeHeader
