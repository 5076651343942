import React from "react";
import { useNavigate } from 'react-router-dom'
import {Link} from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Logo from './Media/Logo.svg'
import Portada01 from './Media/portada-pagina-edicion.jpg'
import Banner from './Media/home/imagen-lateral-izquierda-clickeable.jpg'
import Banner2 from './Media/movil/banner-todas-las-paginas.jpg'

const PDF_URL = 'https://revistamovilidadsegura.cl/CDN/'
const PDF_FILENAME = 'revistamovilidadseguraDic2022.pdf'
const PDF_FILENAME02 = 'MovilidadSeguraEd02.pdf'

function Ediciones(){
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' })
    const isDesktop = !isMobile
    const navigate = useNavigate();
    function avanzar(){
        navigate('/Edicion01')
    }
    function descargar(){
        const a = document.createElement('a')
        a.setAttribute('href', PDF_URL + PDF_FILENAME)
        a.setAttribute('download', PDF_FILENAME);
        a.setAttribute('target', '_blank');
        a.click()
    
    }

    function enviar(){

    }
    return(
    <div className="Edicion01">
        <div className="Edicion01Column1">
            <div className="EdicionHeader">
                {isDesktop &&
                    <div className="EdicionLogo">
                        <img src={Logo} width="246" height="28" />
                    </div>
                }
                {isMobile &&
                    <div className="EdicionLogo">
                        Seguridad vial en Chile, una mirada al futuro 
                    </div>
                }
                <div className="EdicionInfo">Edición 01 / Diciembre 2022</div>
            </div>
            <div className="EdicionBody">
                <div className="EdicionesColumn1">
                    <img className="PortadaEdiciones" src={Portada01} width="184" height="242" alt="Portada Edición 01"/>
                </div>
                <div className="EdicionesColumn2">
                    <div className="EdicionDescrition">
                        En Diciembre se cumplirán 3 años desde que el COVID-19 modificó el acontecer mundial 
                        como nunca antes. En Chile, los impactos de todo orden se han dejado sentir en la vida 
                        cotidiana. La convivencia urbana se ha visto tensada por cambios conductuales de peatones 
                        y conductores. Ha tenido lugar un acelerado proceso de digitalización, a todo nivel. El 
                        desarrollo de nuevos prototipos tecnologicos -de la mano de la inteligencia artificial- abre un 
                        campo de posibilidades a empresas y desarrolladores, para instalar nuevos estándares de 
                        calidad en los procesos de control y gestión de la seguridad vial. 
                        Revista "Seguridad Vial" quiere transformarse en un referente informativo de vanguardia, 
                        que investigue y difunda en la comunidad nacional, los nuevos derroteros de un área 
                        economica estratégica para el crecimiento del país.
                    </div>   
                    <div className="commentContainer">
                        <textarea  className="commentTextArea" 
                                    placeholder="¿Tienes algun tema que crees que sería un aporte a esta revista especializada en seguridad vial?">
                        </textarea>
                    </div>
                    <div className="BotonEnviarContainer">
                        <button className="BotonEnviar" onClick={enviar}>Enviar</button>
                    </div>
                    { isDesktop &&
                        <div className="BotonesContainer">
                            <button className="BotonDescargar MainButton" onClick={descargar}>Descargar Revista</button>
                            <button className="BotonVer MainButton" onClick={avanzar}>Ver Revista</button>
                        </div>
                    }
                </div>
                <div className="EdicionesColumn2">
                    <div className="EdicionDescrition">
                        En Diciembre se cumplirán 3 años desde que el COVID-19 modificó el acontecer mundial 
                        como nunca antes. En Chile, los impactos de todo orden se han dejado sentir en la vida 
                        cotidiana. La convivencia urbana se ha visto tensada por cambios conductuales de peatones 
                        y conductores. Ha tenido lugar un acelerado proceso de digitalización, a todo nivel. El 
                        desarrollo de nuevos prototipos tecnologicos -de la mano de la inteligencia artificial- abre un 
                        campo de posibilidades a empresas y desarrolladores, para instalar nuevos estándares de 
                        calidad en los procesos de control y gestión de la seguridad vial. 
                        Revista "Seguridad Vial" quiere transformarse en un referente informativo de vanguardia, 
                        que investigue y difunda en la comunidad nacional, los nuevos derroteros de un área 
                        economica estratégica para el crecimiento del país.
                    </div>   
                    <div className="commentContainer">
                        <textarea  className="commentTextArea" 
                                    placeholder="¿Tienes algun tema que crees que sería un aporte a esta revista especializada en seguridad vial?">
                        </textarea>
                    </div>
                    <div className="BotonEnviarContainer">
                        <button className="BotonEnviar" onClick={enviar}>Enviar</button>
                    </div>
                    { isDesktop &&
                        <div className="BotonesContainer">
                            <button className="BotonDescargar MainButton" onClick={descargar}>Descargar Revista</button>
                            <button className="BotonVer MainButton" onClick={avanzar}>Ver Revista</button>
                        </div>
                    }
                </div>
            </div>
            { isMobile &&
                        <div className="BotonesContainer">
                            <button className="BotonDescargar MainButton" onClick={descargar}>Descargar Revista</button>
                            <button className="BotonVer MainButton" onClick={avanzar}>Ver Revista</button>
                        </div>
                    }
        </div>
        <div className="ContenedorAds">
            <div className="ContenedorAdsInside">
                <Link to="/Contact" >
                    { isDesktop && <img className="Portada" src={Banner} height="404" width="189"/>}
                    { isMobile && <img className="Portada" src={Banner2} height="334" width="108"/>}
                </Link>
            </div>
        </div>
    </div>
    )
}

export default Ediciones