import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'

import footer_logo from './Media/general/LogoFooter.svg'
function WorkFreeFooter(){

   // const isDesktop = useMediaQuery({ query: '(min-width: 600px)' }) //TODO define break
   // const location = useLocation()
   // const isSitemap = location.pathname=='/sitemap' ? 1:0

   const isDesktop = useMediaQuery({ query: '(min-width: 900px)' })
   const isMobile = !isDesktop
    return(
        <div className="Footer">
            <div className='Column'>
                <div className="FooterTitle">Staff</div>
                <div className="FooterSubTitle">
                    Redacción periodística
                </div>
                <div className="FooterDetail">
                    Carmen Gloria Nova Reyes 
                </div>
                <div className="FooterSubTitle">
                    Administración y finanzas
                </div>
                <div className="FooterDetail">
                    Francisco Guerrero Parada
                </div>
            </div>
            <div className='Column'>
                <div className="FooterTitle">Contacto</div>
                <div className="FooterSubTitle">
                   Editorial, marketing
                </div>
                <div className="FooterDetail">
                    +562 3323 0460
                </div>
                <div className="FooterSubTitle">
                   Correo
                </div>
                <div className="FooterDetail">
                    info@revistamovilidadsegura.cl
                </div>
                <div className="FooterSubTitle">
                   Alcance Nacional
                </div>
                
                {isMobile && 
                    <div className='Column footer_logo'>
                            <img src={footer_logo} width="105" height="12"/>
                    </div>
                }
            </div>
            {isDesktop && 
                <div className='Column footer_logo'>
                        <img src={footer_logo} width="309" height="35"/>
                </div>
            }
        </div>
    )
}

export default WorkFreeFooter