import React, {useState} from 'react'
import {Routes , Route} from 'react-router-dom'

import Home from './Home';
import Who from './Who';
import Contact from './Contact'
import Ediciones from './Ediciones'
import Edicion01 from './Edicion01'
import Edicion02 from './Edicion02'
import Edicion03 from './Edicion03'

import Menu from './menu/Menu'

//TODO add witemap

function SiteRoutes(){    
    return (
    <Routes>
        <Route index path="/" element={<Home/>}/>
        <Route path="/Who" element={<Who/>}/>
        <Route path="/Contact" element={<Contact/>}/>
        <Route path="/Ediciones" element={<Ediciones/>}/>
        <Route path="/Edicion01" element={<Edicion01/>}/>
        <Route path="/Edicion02" element={<Edicion02/>}/>
        <Route path="/Edicion03" element={<Edicion03/>}/>
    </Routes>
    )
}

export default SiteRoutes