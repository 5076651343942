import React,{Suspense,useState} from 'react'
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { useEffect } from "react";
import {FaLongArrowAltRight} from 'react-icons/fa'

import Header from './Header'
import SiteRoutes from './SiteRoutes';
import Footer from './Footer';

//TODO Improve
import Menu from './menu/Menu'
import Toggle from './menu/Toggle';
import './CSS/site.css'

function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}

//TODO move to own file
function MobileMenu(){
    const [navToggled, setNavToggled] = useState(false);

    const handleNavToggle = () => {
      setNavToggled(!navToggled);
    }
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' })
    
    const location = useLocation()
    const isSitemap = location.pathname=='/sitemap' ? 1:0

    return (!isSitemap &&
        <div className="workfreepv_menu">
        { (isMobile && !navToggled) && <Toggle handleNavToggle={handleNavToggle} className="menutoggle"/>}
        { (isMobile && navToggled) ? <Menu handleNavToggle={handleNavToggle} /> : null }
        </div>
    )
}

//TODO move to own file
function RootComponent(){
    
    return(
        <Suspense fallback={<p>Cargando... espere un momento</p>}>
            <BrowserRouter >
                <ScrollToTop />
                <Header />
                <SiteRoutes/>
                <Footer/>
            </BrowserRouter>
        </Suspense> 
    )
}

const MainContainer = document.getElementById('MainContainer');
const MainContainerRoot = createRoot(MainContainer);
MainContainerRoot.render(
    <RootComponent/>
);
