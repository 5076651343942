import React from "react";
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {Link} from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import Portada01 from './Media/portada-home.jpg'
import Portada02 from './Media/revistaEd02.png'
import Portada03 from './Media/portafada03.png'
import Banner from './Media/home/imagen-lateral-izquierda-clickeable.jpg'
import BannerMovil from './Media/movil/banner-todas-las-paginas.jpg'
const PDF_URL = 'https://revistamovilidadsegura.cl/CDN/'
const PDF_FILENAME = 'revistamovilidadseguraDic2022.pdf'
const PDF_FILENAME02 = 'MovilidadSeguraEd02.pdf'
const PDF_FILENAME03 = 'MovilidadSeguraEd03pliego.pdf'

import POSTER from './Media/vistaPreviaVideo.png'

function Home(){
    const location = useLocation()
    const navigate = useNavigate();

    const isHome = location=='/' ? 1:0
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' })
    function avanzar(){
        navigate('/Edicion01')
    }
    function avanzar2(){
        navigate('/Edicion02')
    }
    function avanzar3(){
        navigate('/Edicion03')
    }
    function descargar01(){
        descargar(PDF_URL,PDF_FILENAME)
    }
    function descargar02(){
        descargar(PDF_URL,PDF_FILENAME02)
    }
    function descargar03(){
        descargar(PDF_URL,PDF_FILENAME03)
    }
    function descargar(URL, FILENAME){
        const a = document.createElement('a')
        a.setAttribute('href', URL + FILENAME)
        a.setAttribute('download', FILENAME);
        a.setAttribute('target', '_blank');
        a.click()
    
    }
    return(
    <div className="Home">
        <div className="ContenedorEdiciones">
            <div className="EdicionesTitle">Ediciones</div>
                <div className="EdicionesPortadas"><img className="Portada01" src={Portada03} height="100%" width="236"/></div>
                <div className="PortadaTitle">Edición 03: Seminario Emergencias Industriales</div>
                <div className="BotonesContainer">
                    <button className="BotonDescargar MainButton" onClick={descargar03}>Descargar Revista</button>
                    <button className="BotonVer MainButton" onClick={avanzar3}>Ver Revista</button>
                </div>
                <div className="EdicionesPortadas"><img className="Portada01" src={Portada02} height="100%" width="236"/></div>
                <div className="PortadaTitle">Edición 02: La tecnología como recurso de protección en la conducción</div>
                <div className="BotonesContainer">
                    <button className="BotonDescargar MainButton" onClick={descargar02}>Descargar Revista</button>
                    <button className="BotonVer MainButton" onClick={avanzar2}>Ver Revista</button>
                </div>
                <div className="EdicionesPortadas"><img className="Portada01" src={Portada01} height="100%" width="236"/></div>
                <div className="PortadaTitle">Edición 01: Seguridad vial en Chile</div>
                <div className="BotonesContainer">
                    <button className="BotonDescargar MainButton" onClick={descargar01}>Descargar Revista</button>
                    <button className="BotonVer MainButton" onClick={avanzar}>Ver Revista</button>
                </div>
        </div>
        <div className="ContenedorAds01">
            <div className="ContenedorAdsInside">
                <Link to="/Contact" >
                    {!isMobile && 
                    <img className="Portada" src={Banner} height="404" width="189"/>
                    }
                    {isMobile && 
                    <img className="Portada" src={BannerMovil} height="108" width="334"/>
                    }
                </Link>
            </div>
            <div className="videoContainer">
                <a href="https://revistamovilidadsegura.cl/CDN/videoEvento.mp4" target={'_blank'} ><img src={POSTER}  className="videoObject" ></img>
                <div className="linkVideo">Ver Video</div>
                </a>
            </div>
        </div>
     
    </div>
    )
}

export default Home