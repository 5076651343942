import React,{useState} from 'react' 
import { useMediaQuery } from 'react-responsive'

const form2mailUrl = 'https://revistamovilidadsegura.cl/php/form2mail.php'
//const form2mailUrl = 'http://localhost:8000/form2mail.php' //TODO SOlo para desarrollo local

import logo from './Media/contactanos/logocontactanos.svg'
function Contact(){

    const isMobile = useMediaQuery({ query: '(max-width: 900px)' })
    const isDesktop = !isMobile

    const [largo,updateLargo] = useState(0)

    const updateMensajeLenght = ( value) => {
        updateLargo( value.target.value.length);
    }

    const handleSubmit = () => {
        //TODO add validations
        const nombre = document.getElementById('Contacto_Nombre')
        if( nombre.value.length==0){
            alert('Por favor ingresa tu nombre'); //TODO cambiar a modal
            nombre.focus();
            return;
        }
        const phone = document.getElementById('Contacto_Telefono')
        if( phone.value.length==0){
            alert('Por favor ingresa tu teléfono'); //TODO cambiar a modal
            phone.focus();
            return;
        }
        const mail = document.getElementById('Contacto_Correo')
        if( mail.value.length==0){
            alert('Por favor ingresa tu correo'); //TODO cambiar a modal
            mail.focus();
            return;
        }
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(!mail.value.match(mailformat)){
            alert('Por favor ingresa un correo válido'); //TODO cambiar a modal
            mail.focus();
            return;
        }

        const subject = document.getElementById('Contacto_asunto')
        if( subject.value.length==0){
            alert('Por favor ingresa el asunto'); //TODO cambiar a modal
            subject.focus();
            return;
        }
        const message = document.getElementById('Contacto_Detalle')
        if( message.value.length==0){
            alert('Por favor ingresa el mensaje'); //TODO cambiar a modal
            message.focus();
            return;
        }
        const formData = {
            ['name']: nombre.value,
            ['phone']: phone.value,
            ['from']: mail.value,
            ['subject']: subject.value,
            ['message']: message.value
        };
        (async()=>{
          const response = await fetch(form2mailUrl, {
                mode: 'cors', 
                method: 'POST',
              //  headers: { 'Content-Type': 'application/json' },//TODO add to cors
                body: JSON.stringify(formData) });
              //  const data = await response.json()
                alert("Correo enviado");
       })()
      }

    return (
        <div className="Contact" >
            <div className='contacto_formulario'>
                <div className="Contact_Header">Contáctanos</div>
                <div className="Contact_SubHeader">
                    Para suscribirse o formar parte de la siguiente edición, favor indicar si es publicidad, publirreportaje, aporte o comentario:
                </div>
                <div className='formRow'>
                    <div className='formField'><input type="text" id="Contacto_Nombre" className='contacto_field' placeholder='Nombre y apellido'/></div>
                    <div className='formField'><input type="text"  id="Contacto_Correo" className='contacto_field' placeholder="Dirección de Correo Electrónico"/></div>
                </div>
                <div className='formRow'>
                    <div className='formField'><input type="text"  id="Contacto_Telefono" className='contacto_field' placeholder='Teléfono'/></div>
                    <div className='formField'><input type="text"  id="Contacto_asunto" className='contacto_field' placeholder='Ciudad'/></div>
                </div>
                <div className='textAreaContactoContainer'>
                    <textarea id="Contacto_Detalle" 
                              cols="16" 
                              rows="5" 
                              onChange={updateMensajeLenght} 
                              maxLength="200" 
                              className='textAreaContacto' 
                              placeholder='Comentario'>
                    </textarea>
                </div>
                <div className='contact_buttonContainer'>
                 <button id="contact_button" className="contact_button" onClick={handleSubmit}>Enviar</button>
                </div>
            </div>
            {isDesktop && 
            <div className='contactColumnInfo'>
                <img src={logo} width="199" height="23"/>
                <div className="ContactTitle">Staff</div>
                <div className="ContactSubTitle">
                    Redacción Periodística
                </div>
                <div className="ContactDetail">
                    Carmen Gloria Nova Reyes 
                </div>
                <div className="ContactSubTitle">
                    Administración y finanzas
                </div>
                <div className="ContactDetail">
                    Francisco Guerrero Parada
            </div>
            </div>
        }
        </div>
)}

export default Contact