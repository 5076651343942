import React from "react";
import './CSS/site.css'
function Edicion03(){
    
    React.useEffect(()=>{
        setTimeout(()=>{
            document.getElementById('mensajeEspera').style.display='none'
        },2500)
    },[])
   
    return(
    <div className="Edicion01Pdf">
        <div id="mensajeEspera">Por favor espere un momento mientras se carga la revista.</div>
        <iframe src="https://publuu.com/flip-book/65945/579085/page/1?embed" width="100%" height="380" scrolling="no" frameborder="0" allowfullscreen="" allow="clipboard-write" class="publuuflip pdf"></iframe>
    </div>
    )
}
export default Edicion03