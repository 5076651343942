import React from 'react'
import {Link} from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import Banner from './Media/home/imagen-lateral-izquierda-clickeable.jpg'
import BannerMovil from './Media/movil/banner-todas-las-paginas.jpg'

export default function(){
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' })
    const isDesktop = !isMobile
    return (
    <div className='Who' >
        <div className='WhoMainContainer'>
            <div className='WhoTitle'>
                ¿Quiénes somos?
            </div>
            <div className='WhoBody'>
            <p>Movilidad Segura nace por la necesidad de ofrecer contenido de valor, relacionado con las principales materias de seguridad vial integrada y, de esta manera, hacer una invitación a reflexionar a todo quien tenga acceso al contenido que desarrollamos. Para esto, nos apoyamos en la mirada de expertos que intervienen en esta industria.</p>
            <p>Por otro lado, de acuerdo con lo que dice CONASET, “la Ley de Convivencia Vial tiene como objetivo poner en una posición de igualdad a todos los modos de transportes en las vías. La mitad de los fallecidos en siniestros de tránsito son los usuarios más vulnerables, los peatones, ciclistas y motociclistas”. Ante esto, la información cumple un rol fundamental en la retroalimentación del entramado social, permitiendo tomar conciencia de los riesgos viales y las medidas que, de manera preventiva, se pueden adoptar en torno a esta temática.</p>
            <p>No se respeta lo que no se conoce, por lo tanto, educar en temas viales permitirá que los actores que intervienen en las vías puedan disminuir la ocurrencia de siniestros viales. Ese es nuestro propósito.</p>
            </div>
        </div>
         <div className="ContenedorAds">
            <div className="ContenedorAdsInside">
                <Link to="/Contact" >
                    {isDesktop && <img className="Portada" src={Banner} height="404" width="189"/>}
                    {isMobile && <img className="Portada" src={BannerMovil} height="108" width="334"/>}
                </Link>
            </div>
        </div>
    </div>
)}