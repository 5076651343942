import React,{useState} from "react";
import { useMediaQuery } from 'react-responsive'
import './CSS/site.css'
function Edicion01(){
    
    React.useEffect(()=>{
        setTimeout(()=>{
            document.getElementById('mensajeEspera').style.display='none'
        },2500)
    },[])
   
    return(
    <div className="Edicion01Pdf">
        <div id="mensajeEspera">Por favor espere un momento mientras se carga la revista.</div>
        <iframe src="https://publuu.com/flip-book/65945/196703/page/1?embed" width="100%" height="800" scrolling="no" frameBorder="0" allowFullScreen="" className="publuuflip pdf"></iframe>
    </div>
    )
}

export default Edicion01