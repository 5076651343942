import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'

import { FaTimes } from 'react-icons/fa';
import { FaAngleDown } from 'react-icons/fa'

const Menu = ({handleNavToggle}) => {
    const location = useLocation()
    const isSitemap = location.pathname=='/sitemap' ? 1:0
    return (
        <StyledMenu className='menu_body'>
            <StyledLink className="animate__animated animate__fadeInRight menu_item" onClick={handleNavToggle} to="/">Inicio</StyledLink>
            <StyledLink className="animate__animated animate__fadeInRight menu_item" onClick={handleNavToggle} to="/Who">Quiénes Somos</StyledLink>
            <StyledLink className="animate__animated animate__fadeInRight menu_item" onClick={handleNavToggle} to="/Contact">Contáctanos <FaAngleDown/></StyledLink>
            {!isSitemap && <CloseToggle className="animate__animated animate__fadeInRight" onClick={handleNavToggle}><FaTimes/></CloseToggle>}
        </StyledMenu>
    )
}

const StyledMenu = styled.div`
    position: fixed;
    top: 100px;
    right: 0;
    width: 100%;
    background-color: rgb(4, 90, 107, .9);
    z-index: 3099;
    display: flex;
    flex-direction: column;
    font-weight:bold;
`;

const StyledLink = styled(Link)`
    color: #eee;
    text-decoration: none;
    
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    transition: .5s all ease-in-out;

    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */

    &:hover {
        transition: .2s all ease-in-out;
        color: orangered;
    }
`;

const CloseToggle = styled.button`
    position: fixed;
    z-index:5000;
    top: 20px;
    right: 4%;
    background: transparent;
    color: #fff;
    padding: .75rem;
    display: flex;
    place-items: center;
    font-size: 2rem;
    cursor: pointer;
    
`;

export default Menu
